import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

// Use the new createRouter function instead of VueRouter
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: Home
    }
    // Optionally, for lazy-loading:
    // {
    //   path: '/glitch',
    //   name: 'Glitch',
    //   component: () => import(/* webpackChunkName: "glitch" */ '../views/Glitch.vue')
    // }
  ]
});

export default router;
