<template>
    <div id="logo">
        <img id="baymax" src="@/assets/logo.png" />
        <div id="glitchwrap">
            <!-- <div class="glitch" data-trick="of FRED'S GLITCH"></div> -->
            <div class="glitch-before"></div>
            <img v-if="episode" id="episode" src="@/assets/logo-fred.png" />
            <div class="glitch-after"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Logo',
  props: ['episode']
}
</script>
<style scoped lang="sass">

$black: #1D1E22
$prime: #FF6E48
$second: #0C1016

*
    box-sizing: border-box


@keyframes noise
    $steps: 25
    @for $i from 0 through $steps
        #{ percentage($i * (1/$steps))}
            clip: rect(random(12)+rem, 99.99rem, random(12)+rem, 0)

#logo
    width:100%
    padding: 20px
    box-sizing: border-box
    position: relative
    z-index:1
    left:0
    top:0
#baymax
    width:100%
#glitchwrap
    position: relative
#episode
    width:100%
    margin-top:10px
    position: relative
.glitch-before
        position: absolute
        top: 0
        left: -1rem
        color: white
        background-color: $black
        overflow: hidden
        width: 100%
        height: 100%
        text-shadow: 0.3rem 0 $prime
        clip: rect(0, 90rem, 0, 0)
        animation: noise 4s infinite linear alternate-reverse
.glitch-after
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: repeating-linear-gradient(0deg, rgba($black, .5), rgba($black, .5) 1px, transparent 1px, transparent 2px)
        pointer-events: none
        z-index: 9999
        animation: noise 2s infinite linear alternate-reverse

</style>

