<template>
    <p><i>Baymax Dreams of Fred's Glitch</i> is the first premium, interactive animated short of its kind to be fully remote rendered with touch interaction.</p>
</template>

<script>
export default {
  name: 'CopyFredsGlitch'
}
</script>

