import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "video.js/dist/video-js.css";
// const checkAuth = require("./checkAuth.js");

const app = createApp(App);
app.use(router);
app.mount("#app");

// const redir = () => {
//   window.location = "https://disney.com";
// };

// (async () => {
//   if (window.location.host.includes("localhost:")) return createApp();
//   checkAuth(createApp, redir);
// })();
