<template>
    <p><i>Baymax Dreams</i> premiered on DisneyNOW and Disney Channel YouTube in 2018, inspired by Disney Television Animation's (DTVA) 'Big Hero 6 The Series' TV show. The two-minute shorts explore the content of Baymax's dreams, whether he's counting electric sheep or dodging virtual bedbugs. The episodes were created using a real-time production pipeline, which streamlines the process of creating content for multiple platforms and formats.</p>
</template>

<script>
export default {
  name: 'CopyBaymax'
}
</script>

