<template>
    <div>
        <video ref="videoPlayer" class="video-js"></video>
    </div>
</template>

<script>
import videojs from 'video.js';

export default {
    name: "VideoPlayer",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            player: null
        }
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            console.log('onPlayerReady', this);
        })
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>
<style lang="sass">
.video-js
    width: 100% !important
    video.vjs-tech
        position: relative
    button
        background: none
        border: none
        color: inherit
        display: inline-block
        font-size: inherit
        line-height: inherit
        text-transform: none
        text-decoration: none
        transition: none
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none
        &.vjs-big-play-button
            -webkit-transform: translate(-50%, -50%)
            transform: translate(-50%, -50%)
            backface-visibility: hidden
            -webkit-backface-visibility: hidden
            position: absolute
            top: 50%
            left: 50%
            zoom: 150%
    .vjs-control .vjs-button
        width: 100%
        height: 100%
.vjs_video_3-dimensions
    width: 100%
    height: auto

</style>