<template>
  <div id="page">
    <Logo />
    <div id="main">
      <CopyFredsGlitch />
      <!-- <Videos /> -->
      <BTS />
      <CopyBaymax />
      <!-- <Contact /> -->
      <Footer />
    </div>
  </div>
</template>

<script>
// import Videos from '@/components/Videos.vue'
import BTS from "@/components/BTS.vue";
import CopyBaymax from "@/components/CopyBaymax.vue";
import CopyFredsGlitch from "@/components/CopyFredsGlitch.vue";
//import Contact from '@/components/Contact.vue'
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    BTS,
    //Contact,
    CopyBaymax,
    CopyFredsGlitch,
    Logo,
    Footer
    // Videos
  }
};
</script>

<style scoped lang="sass"></style>
