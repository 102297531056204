<template>
  <section id="footer" class="text">
    <div id="ft-logo">
      <img
        width="80px"
        height="33px"
        src="https://static-mh.content.disney.io/matterhorn/assets/logos/nav_logo@2x-25d10ad375dd.png"
      />
    </div>
    <nav role="navigation" aria-labelledby="about-legal-heading">
      <ul>
        <li>
          <a
            target=""
            href="https://www.thewaltdisneycompany.com/?ppLink=pp_wdig"
            >About Disney</a
          >
        </li>
        <li><a target="" href="https://help.disney.com/">Disney Help</a></li>
        <li><a target="" href="https://jobs.disneycareers.com/">Careers</a></li>
        <li><a target="" href="https://help.disney.com/">Contact Us</a></li>
        <li>
          <a target="" href="https://ddn.disney.com/">Advertise With Us</a>
        </li>
        <li>
          <a
            target=""
            href="https://disneyrewards.com/disney-premier-credit-card-ph/?CELL=62FV19"
            >Disney® Premier Visa® Card</a
          >
        </li>
      </ul>
      <ul>
        <li>
          <a target="" href="https://disneytermsofuse.com/">Terms of Use</a>
        </li>
        <li>
          <a
            target=""
            href="https://help.disney.com/articles/en_US/FAQ/Legal-Notices"
            >Additional Content Information</a
          >
        </li>
        <li>
          <a target="" href="https://privacy.thewaltdisneycompany.com/en/"
            >Privacy Policy</a
          >
        </li>
        <li>
          <a
            target=""
            href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/your-us-state-privacy-rights/"
            >Your US State Privacy Rights</a
          >
        </li>
        <li>
          <a
            target=""
            href="https://privacy.thewaltdisneycompany.com/en/for-parents/childrens-online-privacy-policy/"
            >Children's Online Privacy Policy</a
          >
        </li>
        <li>
          <a
            target=""
            href="http://preferences-mgr.truste.com/?type=disneycolor&affiliateId=115"
            >Interest-Based Ads</a
          >
        </li>
        <li>
          <a
            target=""
            class="ot-sdk-show-settings"
            href="https://privacy.thewaltdisneycompany.com/en/dnssmpi/"
            >Do Not Sell or Share My Personal Information</a
          >
        </li>
      </ul>
      <div>
        © Disney, All Rights Reserved
      </div>
    </nav>
  </section>
</template>

<script>
export default {
  name: "Footer"
};
</script>
<style scoped lang="sass">

#footer
  margin-top: 50px
  text-align: center
  color: #fff
  font-size:.6em
  padding-bottom:50px
  a, a:link, a:hover, a:visited
    text-decoration: none
    color: #fff
  a:hover
    text-decoration: underline
  ul
    list-style: none
    padding: 0 0 10px 0
    li
      display: inline-block
      padding: 4px 10px 0 10px
</style>
